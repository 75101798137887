import { lazy } from "react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import { kcContext as kcLoginThemeContext } from "./keycloak-theme/login/kcContext";
import { kcContext as kcAccountThemeContext } from "./keycloak-theme/account/kcContext";

const App = lazy(() => import("./App"));
const KcLoginThemeApp = lazy(() => import("./keycloak-theme/login/KcApp"));
const KcAccountThemeApp = lazy(() => import("./keycloak-theme/account/KcApp"));
const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

// As `kc*Context` is only set when loaded during `yarn keycloakify`,
// this'll never run in a webbrowser.
if (!!kcLoginThemeContext) {
    root.render(<KcLoginThemeApp kcContext={kcLoginThemeContext} />);
} else if (!!kcAccountThemeContext) {
    root.render(<KcAccountThemeApp kcContext={kcAccountThemeContext} />);
} else {
    root.render(<App />);

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
}
